var menu = [
    {
        title: "Dashboard",
        permission: "view_analytics",
        children: [
            {
                title: "Main Dashboard",
                route: "dashboard",
                icon: "HomeIcon",
                permission: "view_analytics",
            },
            {
                title: "CMS Analytics",
                route: "cms-analytics",
                icon: "TrendingUpIcon",
                permission: "view_cms_analytics",
            },
            {
                title: "App Statistics",
                route: "time-graphs",
                icon: "BarChart2Icon",
                permission: "view_time_graphs",
            },
        ],
    },
    {
        title: "General",
        permission: "view_generals",
        children: [
            {
                title: "Topics",
                route: "topics",
                icon: "MenuIcon",
                permission: "view_topics",
            },
            {
                title: "Grades",
                route: "Classes",
                icon: "StarIcon",
                permission: "view_classes",
            },
            {
                title: "Gallery",
                route: "Gallery",
                icon: "ImageIcon",
                permission: "view_gallery",
            },
            {
                title: "Videos",
                route: "Videos",
                icon: "PlayCircleIcon",
                permission: "view_videos",
            },
            {
                title: "Audios",
                route: "Audios",
                icon: "PlayCircleIcon",
                permission: "view_videos",
            },
            {
                title: "Feedbacks",
                route: "feedback-questions",
                icon: "MessageSquareIcon",
                permission: "view_feedback",
            },
            {
                title: "Old Pending Revisions",
                route: "old-pending-revisions",
                icon: "RotateCwIcon",
                permission: "view_revisions",
            },
            {
                title: "New Pending Revisions",
                route: "new-pending-revisions",
                icon: "RotateCwIcon",
                permission: "view_revisions",
            },
            {
                title: "App Users",
                route: "app-users",
                icon: "UsersIcon",
                permission: "view_app_users",
            },
            {
                title: "Push Notifications",
                route: "push-notifications",
                icon: "MailIcon",
                permission: "view_app_push_notifications",
            },
            {
                title: "Notification Types",
                route: "notification-types",
                icon: "MailIcon",
                permission: "view_app_notifications_type",
            },
            {
                title: "Leaderboard",
                route: "leaderboard",
                icon: "UsersIcon",
                permission: "view_leaderboard",
            },
            {
                title: "Ads Management",
                route: "ads-management",
                icon: "MonitorIcon",
                permission: "view_ads_management",
            },
            {
                title: "Ads Account",
                route: "ads-account",
                icon: "UsersIcon",
                permission: "view_ads_account",
            },
        ],
    },
    {
        title: "Review",
        permission: "view_reviews",
        children: [
            {
                title: "Topics Review Board",
                route: "topics-board",
                icon: "MailIcon",
                permission: "view_topics_review_board",
            },
            {
                title: "Topics Review Statuses",
                route: "topics-review-statuses",
                icon: "MailIcon",
                permission: "view_topics_review_statuses",
            },
        ]
    },
    {
        title: "Settings",
        permission: "view_settings",
        children: [
            {
                title: "Gallery Tags",
                route: "gallery-tags",
                icon: "TagIcon",
                permission: "view_gallerytags",
            },
            {
                title: "Deleted Questions",
                route: "deleted-question",
                icon: "TrashIcon",
                permission: "view_deletedquestion",
            },
            {
                title: "Question Themes",
                route: "question-themes",
                icon: "SettingsIcon",
                permission: "view_themes",
            },
            {
                title: "Old Question Types",
                route: "old-question-types",
                icon: "CornerRightUpIcon",
                permission: "view_questionType",
            },
            {
                title: "New Question Types",
                route: "new-question-types",
                icon: "CornerRightUpIcon",
                permission: "view_questionType",
            },
            {
                title: "CMS Users",
                route: "cms-users",
                icon: "UsersIcon",
                permission: "view_users",
            },
            {
                title: "Roles",
                route: "roles",
                icon: "ToolIcon",
                permission: "view_role",
            },
            {
                title: "Permissions",
                route: "permission-users",
                icon: "KeyIcon",
                permission: "view_permission",
            },
        ],
    },
    {
        title: "Billing",
        permission: "view_billing",
        children: [
            {
                title: "Plans",
                route: "billing-plans",
                icon: "TagIcon",
                permission: "view_gallerytags",
            },
        ],
    },
];
let final_menu = [];

const setMenu = (permission) =>{
    let nested_arry_setting = [];
    let nested_arry_generals = [];
    let nested_arry_reviews = [];
    menu.forEach((item) => {
        for (let index = 0; index < permission.length; index++) {
            if (item.permission == permission[index]) {
                if (item.permission == "view_settings") {
                    var child_setting = item.children;
                    for (let ind = 0; ind < child_setting.length; ind++) {
                        for (let j = 0; j < permission.length; j++) {
                            if (
                                child_setting[ind].permission == permission[j]
                            ) {
                                nested_arry_setting.push(child_setting[ind]);
                            }
                        }
                    }
                    item.children = nested_arry_setting;
                    final_menu.push(item);
                }else if (item.permission == "view_generals") {
                    var child_setting = item.children;
                    for (let ind = 0; ind < child_setting.length; ind++) {
                        for (let j = 0; j < permission.length; j++) {
                            if (
                                child_setting[ind].permission == permission[j]
                            ) {
                                nested_arry_generals.push(child_setting[ind]);
                            }
                        }
                    }
                    item.children = nested_arry_generals;
                    final_menu.push(item);
                }
                else if (item.permission == "view_reviews") {
                    var child_setting = item.children;
                    for (let ind = 0; ind < child_setting.length; ind++) {
                        for (let j = 0; j < permission.length; j++) {
                            if (
                                child_setting[ind].permission == permission[j]
                            ) {
                                nested_arry_reviews.push(child_setting[ind]);
                            }
                        }
                    }
                    item.children = nested_arry_reviews;
                    final_menu.push(item);
                } else {
                    final_menu.push(item);
                }
            }
        }
    });
};

window.addEventListener('refreshSidebar', (event) => {
    if (event['detail'].value) {
        // console.log('refreshSidebar=', event['detail'].permissions);
        const permission = event['detail'].permissions
        setMenu(permission);
    }
});

if (localStorage.getItem("permission")) {
    let p_data = JSON.parse(localStorage.getItem("permission"));
    const permission = p_data.permission;
    setMenu(permission);
};

// console.log("final_menu",final_menu);
export default final_menu;
