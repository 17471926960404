var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n        main-menu\n        menu-fixed menu-accordion menu-shadow\n        cu-navbar-bg-white cu-navbar\n    ",class:[
        {
            expanded:
                !_vm.isVerticalMenuCollapsed ||
                (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
        },
        _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded justify-content-center",class:_vm.shallShadowBottom?'active':''},[_vm._t("header",function(){return [_c('b-link',{staticClass:"navbar-brand justify-content-center",attrs:{"to":"/"}},[_c('span',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"src":"/logo.png","alt":"logo"}})],1)])]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area cu-navbar-bg-white",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
                _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
            }}},[_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main cu-navbar-bg-white ps mb-5",attrs:{"items":_vm.navMenuItems}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }